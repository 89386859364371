import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'


function BlogPostTemplate(props) {
  const post = props.data.markdownRemark
  const siteTitle = props.data.site.siteMetadata.title
  const { previous, next, slug } = props.pageContext
  // site.siteMetadata.siteUrl + 'articulo' + edge.node.fields.slug
  let disqusConfig = {
    url: `${props.data.site.siteMetadata.siteUrl}/articulo${slug}`,
    identifier: props.data.markdownRemark.id,
    title: post.frontmatter.title,
  }
  // console.log(disqusConfig)
  return (
    // var imgPath = post.frontmatter.thumbnail.split("/content/assets/")[1]
    // var img = lls[lls.length - 1].split(".")[0]
    <Layout location={props.location} title={siteTitle}>
      <SEO title={post.frontmatter.title} description={post.excerpt} slug={slug} />
      <Image
        fixed={post.frontmatter.thumbnail.childImageSharp.fixed}
        alt={post.frontmatter.title}
        id="img-blog-post"
        className="img__blog-post"
        style={{
          // marginRight: rhythm(1 / 2),
          marginBottom: 0,
          minWidth: 300,
          borderRadius: `5%`,
          display: 'block'
        }}
        imgStyle={{
          borderRadius: `5%`,
        }}
      />
      <h1>{post.frontmatter.title}</h1>
      {/* <p>{post.frontmatter.thumbnail.publicURL}</p> */}
      {/* //thumbnail */}
      <p
        style={{
          ...scale(-1 / 5),
          display: `block`,
          marginBottom: rhythm(1),
          marginTop: rhythm(-1),
        }}
      >
        {post.frontmatter.date}
      </p>
      <div dangerouslySetInnerHTML={{ __html: post.html }} />
      <hr
        style={{
          marginBottom: rhythm(1),
        }}
      />
      <Bio />

      <ul
        style={{
          display: `flex`,
          flexWrap: `wrap`,
          justifyContent: `space-between`,
          listStyle: `none`,
          padding: 0,
        }}
      >
        <li>
          {previous && (
            <Link to={`/articulo${previous.fields.slug}`} rel="prev">
              ← {previous.frontmatter.title}
            </Link>
          )}
        </li>
        <li>
          {next && (
            <Link to={`/articulo${next.fields.slug}`} rel="next">
              {next.frontmatter.title} →
              </Link>
          )}
        </li>
      </ul>
      {/* <CommentCount config={disqusConfig} placeholder={'...'} /> */}
      <Disqus config={disqusConfig} />
    </Layout>
  );
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
              thumbnail {
        absolutePath
        publicURL
        childImageSharp {
          fixed {
            base64
            width
            tracedSVG
            srcWebp
            srcSetWebp
            srcSet
            src
            originalName
            height
            aspectRatio
          }
        }
      }
      }
    }
  }
`
